@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300&display=swap');

:root {
  --site-title-font-size: 2rem;
  --site-title-mobile-font-size: .9375rem;
  --nav-links-font-size: 1rem;
  --nav-links-mobile-font-size: 2rem;
  --main-font-size: 1rem;
  --main-font-color: #676767;
}

* {
  box-sizing: border-box;
  
}

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  color: var(--main-font-color); 
}

.app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.main {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/*.content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}*/


a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li, h1 {
  padding: 0;
  margin: 0;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6vw;
  z-index: 2;
}

.site-title {
  width: 100%;
  font-size: var(--site-title-mobile-font-size);
  letter-spacing: .2rem;
  height: auto;
  cursor: pointer;
}

.desktop-nav {
  display: none;
}

.mobile-nav-links {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  padding-top: calc(50px + 25vh);
  width: 100%;
  height: 100vh;
  background-color: white;
  text-align: center;
  font-size: var(--nav-links-mobile-font-size);
  z-index: 2;
}

/* animated mobile menu transitions for soft fade in and out */
.fadeIn {
  animation: .5s fadeIn ease-in forwards;
  display: flex;
}

.fadeOut {
  animation: .5s fadeOut ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.nav-links a {
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 0 solid var(--main-font-color);
  transition: all .5s ease-in-out;
  cursor: pointer;
}

a.insta-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

a.insta-icon svg {
  padding-top: 8px;
}

.nav-links a:hover,
.nav-links a:active {
  border-bottom: 1px solid var(--main-font-color);
}

a.insta-icon:hover {
  border-bottom: none;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: none;
  background-color: white;
  padding: 0;
  z-index: 3;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: var(--main-font-color);
  border-radius: 1px;
  transition: all .2s ease-in-out;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 3.5px);
}

.hamburger-menu.open .bar:nth-child(2) {
  transform: rotate(45deg) translate(-5px, -3.5px);
}

/* Footer */
.footer {
  text-align: center;
}

.footer p {
  font-size: .875rem;
}

/* Home Page */

.hero-image {
  width: 75%;
  max-width: 400px;
  margin-bottom: 80px; /* update with calc? */
}

/* Gallery  */
.slider-wrapper {
  width: 90%;
  max-width: 700px;
  height: auto;
  margin-bottom: 40px;
}

.slick-slide {
  width: 100%;
  max-width: 700px;
  padding: 20px;
}

.slick-prev::before,.slick-next::before {
  display:none;
  }

.slick-arrow {
  z-index: 1;
}

.arrows .slick-next { 
  top: 45%;
  right: 0;
}
.arrows .slick-prev {
  top: 45%;
  left: 0;
}

.arrows {
  font-size: 1rem;
  color: gray;
  transition: all .2s ease-in-out;
}

.arrow {
  transform: scale(1);
}

.arrow:hover {
  transform: scale(1.2);
}

.slide {
  width: 100%;
  text-align: center;
}

.img-wrapper {
  width: 100%;
  height: auto;
  aspect-ratio: 5/6;
  padding: 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-wrapper {
  margin-top: 12px;
}

.slide p {
  font-size: .75rem;
  margin: 0;
  
}

.title {
  font-style: italic;
}

/*  About  */
.about-wrapper {
  width: 85%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.about-statement {
  width: 100%;
  text-align: left;
}

.about-info {
  width: 100%;
  text-align: left;
}

.about-contact {
  width: 100%;
  margin-top: 20px;
  text-align: left;
}

.about-info p,
.about-statement p,
.about-contact p {
  line-height: 1.5;
  margin-top: 15px;
}

.bio-title {
  text-decoration: underline;
  text-transform: lowercase;
}

.section-break {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-font-color);
  margin: 40px 0;
}


@media (min-width: 600px) {
  /* Home  */
  .hero-image {
    width: 70%;
    max-width: 700px;
  }
  
  /* Gallery  */

  .slick-slide {
    padding: 0 40px;
  }
}

@media (min-width: 769px) {
  .mobile-nav {
    display: none;
  }

  .desktop-nav {
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2.2vw 3vw;
  }

  .site-title {
    font-size: var(--site-title-font-size);
    width: 50%;
    padding-bottom: 3px;
  }

  .desktop-nav-links {
    width: 50%;
    position: unset;
    display: flex;
    gap: 30px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0;
    margin-bottom: 5px;
  }
  

  .desktop-nav-links a {
    font-size: var(--nav-links-font-size);
  }


  a.insta-icon svg {
    padding-top: 0;
    margin-bottom: -1px;
  }

  .hamburger-menu {
    display: none;
  }

  /* Home Page */

  .hero-image {
    width: 70%;
    max-width: 700px;
  }

  /* Gallery  */
  .slider-wrapper {
    margin-bottom: 60px;
  }

  .slick-slide {
    padding: 0 60px;
  }


}
